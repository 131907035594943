import React from 'react'
import { Trans } from 'react-i18next'
import { Styled } from './styled/Toast.styled'
import { applyRegistration } from '../../utils/serviceWorker'

type Props = {
  serviceWorkeregistration: ServiceWorkerRegistration
  className?: string
}
export function Toast({ className, serviceWorkeregistration }: Props) {
  return (
    <Styled.Container className={className}>
      <Styled.Text>
        {' '}
        <Trans i18nKey="toast.new.version.available" />
      </Styled.Text>
      <Styled.ConfirmButton
        text={<Trans i18nKey="toast.update" />}
        withShadow={true}
        onClick={() => applyRegistration(serviceWorkeregistration)}
      />
    </Styled.Container>
  )
}
