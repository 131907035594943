function twoDigits(numStr: string) {
  if (numStr.length < 2) {
    return `0${numStr}`
  }

  return numStr
}

export function formatDateStr(date: string, delimiter = '-') {
  const parts = date.split('.')
  if (parts.length === 3) {
    return [parts[2], twoDigits(parts[1]), twoDigits(parts[0])].join(delimiter)
  }

  return date
}

export function formatDate(date: Date, targetLocale: 'en' | 'cs' = 'en') {
  let month = `${date.getMonth() + 1}`
  let day = `${date.getDate()}`
  const year = date.getFullYear()

  if (month.length < 2) {
    month = `0${month}`
  }
  if (day.length < 2) {
    day = `0${day}`
  }

  return targetLocale === 'en' ? [year, month, day].join('-') : [day, month, year].join('.')
}

export function prettyDate(date: number) {
  return new Date(date * 1000).toLocaleDateString()
}

export function prettyDateString(dateStr?: string, monthsFormat?: Intl.DateTimeFormatOptions['month']) {
  if (!dateStr) {
    return ''
  }
  const date = new Date(dateStr)
  const ye = new Intl.DateTimeFormat('cs', { year: 'numeric' }).format(date)
  const mo = new Intl.DateTimeFormat('cs', { month: monthsFormat || 'long' }).format(date)
  const da = new Intl.DateTimeFormat('cs', { day: '2-digit' }).format(date)
  return `${da} ${mo} ${ye}`
}

export function validateCsDate(date: string) {
  const dateRegExp = new RegExp('^([0]?[1-9]|[1|2][0-9]|[3][0|1])[\\.]([0]?[1-9]|[1][0-2])[\\.]([0-9]{4})$')
  return dateRegExp.test(date)
}

export function getDateDigitsChain(date?: number) {
  const d = date || Date.now()
  const fullDate = new Date(d)

  return `${fullDate.getFullYear()}${fullDate.getMonth()}${fullDate.getDate()}`
}
