import styled, { css } from 'styled-components'
import { ColorSets } from '../../../colors/colors'
import { colorSet } from '../../../mixin/colorSet'

const Button = styled.button<{ showShaddow?: boolean; defaultSpacing?: boolean; $bgColorSet?: ColorSets }>`
  position: relative;
  padding: 15px;
  font-size: 18px;
  font-weight: 500;
  border: 1px solid ${colorSet(ColorSets.BLUE_MAIN_12)};
  border-radius: 8px;
  min-width: 170px;
  color: ${colorSet(ColorSets.GRAY_BLUE_65)};
  background-color: ${props => colorSet(props.$bgColorSet || ColorSets.WHITE)};
  cursor: pointer;
  ${props =>
    props.defaultSpacing &&
    css`
      margin: 40px;
    `};
  ${props =>
    props.showShaddow &&
    css`
      box-shadow: ${colorSet(ColorSets.BLUE_MAIN_SHADOW)} 0px 1px 12px;
    `};
`

export const Styled = {
  Button
}
