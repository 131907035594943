import { Item } from '../components/payment/types'
import { BASIC_REPORT_PRICE } from '../constants/app'

export const NON_BREAKABLE_SPACE = String.fromCharCode(160)

export function getPaymentItem(inputData?: Partial<Item>): Item {
  const {
    description = 'Základní výpis z registru exekucí',
    executionId = null,
    price = BASIC_REPORT_PRICE,
    searchId = null,
    customId,
    warning
  } = inputData || {}
  return {
    description,
    price,
    executionId,
    searchId,
    customId,
    warning
  }
}
function joinParts(parts: string[]) {
  return parts.filter(valid => valid).join('.')
}

export function splitThousands(n: string | number | null, separator: string) {
  if (n === null) {
    return null
  }
  const [significantDigits, decimalsDigits] = n.toString().split('.')
  const fixed = significantDigits.replace(/\B(?=(\d{3})+(?!\d))/g, separator)
  return joinParts([fixed, decimalsDigits])
}
