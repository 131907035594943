import { TFunction } from 'i18next'
import { eligibleStatusMap, exportTypeMap, formAddress, formMark, formName, formState } from './transforms'
import {
  DetailData,
  Eligible,
  IsirData,
  ResultRecord,
  Subject,
  trials,
  PersonType,
  GeneralResponseData,
  ResultChips,
  DphData,
  DphResult,
  Document,
  Enforcement
} from './types'
import { ColorSets } from '../../colors/colors'
import { isCed } from '../../utils/app'
import { getDateDigitsChain, prettyDateString } from '../../utils/dateUtils'
import { splitThousands } from '../../utils/paymentUtils'
// import { stringHashCode } from '../../utils/stringUtils'

/* eslint-disable camelcase */

function getLongPersonType(type: PersonType, t: TFunction) {
  return type === 'F' ? t('results.detail.person.F') : type === 'P' ? t('results.detail.person.P') : ''
}
function getShortPersonType(type: PersonType, t: TFunction) {
  return type === 'F' ? t('results.detail.person.short.F') : type === 'P' ? t('results.detail.person.short.P') : ''
}

export const createItem = (name: string, value: string, isLong?: boolean, description?: string) => {
  return {
    name,
    value,
    isLong,
    description
  }
}

export function getDocumentRows(document: Document, t: TFunction) {
  const { document_type_description, document_vydano, document_vlozeno, jmeno_soudu, spisova_znacka_soudu } = document
  return [
    createItem(t('document.type'), document_type_description),
    createItem(t('document.created'), prettyDateString(document_vydano) || document_vydano),
    createItem(t('document.inserted'), prettyDateString(document_vlozeno) || document_vlozeno),
    createItem(t('document.trial.name'), jmeno_soudu),
    createItem(t('document.trial.mark'), spisova_znacka_soudu)
  ]
}

export function getEnforcementRows(enforcement: Enforcement, t: TFunction) {
  const { issued_at, issuer, reference_number, enforcement_type_description } = enforcement
  return [
    createItem(t('enforcement.created'), prettyDateString(issued_at) || issued_at),
    createItem(t('enforcement.creator'), issuer),
    createItem(t('enforcement.mark'), reference_number),
    createItem(t('enforcement.type'), enforcement_type_description)
  ]
}

export function getSubjectRows(subject: Subject, t: TFunction) {
  const personType = getLongPersonType(subject.typ_osoby, t)
  const dateBirth = subject.datum_narozeni ? prettyDateString(subject.datum_narozeni) : ''
  const terminationDate = subject.auto_close_at ? prettyDateString(subject.auto_close_at, '2-digit') : ''
  const terminationInfo = terminationDate
    ? {
        text: t('subject.terminatedAt', { date: terminationDate }),
        tooltip: t('subject.terminatedAt.tooltip', { date: terminationDate }),
        colorSet: ColorSets.FOREST_GREEN
      }
    : ''
  return [subject.jmeno, dateBirth, subject.adresa, personType, terminationInfo].filter(f => !!f)
}

export function downloadPdf(blob: Blob, filename: string) {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename
  document.body.append(link)
  link.click()
  link.remove()
  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}

export function isProduction() {
  return process.env.NODE_ENV && process.env.NODE_ENV === 'production'
}

export function isProductionAPI() {
  return process.env.REACT_APP_API_ENV && process.env.REACT_APP_API_ENV === 'production'
}

export const getCardData = (record: ResultRecord, t: TFunction) => {
  return [
    {
      name: t('results.detail.name'),
      value: record.name
    },
    {
      name: t('results.detail.birth'),
      value: record.birthDate ? prettyDateString(record.birthDate) : ''
    },
    {
      name: t('results.detail.address'),
      value: record.address
    },
    // {
    //   name: t('results.detail.ex.name'),
    //   value: record.executorName || ''
    // },
    // {
    //   name: t('results.detail.ex.email'),
    //   value: record.executorEmail || ''
    // },
    {
      name: t('results.detail.person'),
      value: getLongPersonType(record.personType, t)
    },
    {
      name: t('results.general.execution.dateFrom'),
      value: prettyDateString(record.executionDate) || record.executionDate
    }
  ].filter(v => !!v.value)
}

export const getTrialShort = (trialFullName: string) => {
  const fullName = trialFullName.replace(/[\s|–|-]/g, '')
  const trial = trials.find(t => t.name === fullName)

  return trial?.short || ''
}

export const getDphCardData = (record: DphData, t: TFunction) => {
  return [
    {
      name: t('general.dph.fuNumber'),
      value: record.cisloFu
    },
    {
      name: t('general.dph.dic'),
      value: t('general.dph.dic.number', { dic: record.dic })
    },
    {
      name: t('general.dph.date.start'),
      value: prettyDateString(record.datumZverejneniNespolehlivosti)
    }
  ].filter(v => !!v.value)
}

export const getIsirCardData = (record: IsirData, t: TFunction, includeUrl?: boolean) => {
  return [
    {
      name: t('general.isir.mark'),
      value: formMark(record)
    },
    {
      name: t('results.detail.name'),
      value: formName(record)
    },
    {
      name: t('general.isir.rc'),
      value: record.rc
    },
    {
      name: t('results.detail.address'),
      value: formAddress(record)
    },
    {
      name: t('general.isir.state'),
      value: formState(record)
    },
    {
      name: t('general.isir.start'),
      value: prettyDateString(record.datumPmZahajeniUpadku)
    },
    {
      name: t('general.isir.end'),
      value: prettyDateString(record.datumPmUkonceniUpadku)
    },
    {
      name: t('general.isir.detai.url'),
      value: includeUrl ? record.urlDetailRizeni : '',
      isLink: true
    }
  ].filter(v => !!v.value)
}

export function getExecutionsTableRows(originalResults: ResultRecord[], t: TFunction) {
  const tableTitles = [
    'results.general.table.name',
    'results.general.table.created',
    'results.general.table.ex.number'
  ].map(title => t(title))
  const results = originalResults.map(result => ({
    id: result.customId,
    values: [result.name, prettyDateString(result.executionDate, 'short'), result.executionId]
  }))

  return { tableTitles, results }
}

export function getDphTableRows(originalResults: DphData[], t: TFunction) {
  const tableTitles = ['general.dph.fuNumber', 'general.dph.date.start.short', 'general.dph.dic'].map(title => t(title))
  const results = originalResults.map(result => ({
    id: result.dic,
    values: [
      result.cisloFu,
      prettyDateString(result.datumZverejneniNespolehlivosti, 'short'),
      t('general.dph.dic.number', { dic: result.dic })
    ]
  }))

  return { tableTitles, results }
}

export function getInsolvencyTableRows(originalResults: IsirData[], t: TFunction) {
  const tableTitles = ['results.general.table.name', 'results.general.table.created', 'general.isir.state'].map(title =>
    t(title)
  )
  const results = originalResults.map((result, i) => {
    const isStartDateAvailable = !!result.datumPmZahajeniUpadku
    const formattedDate = prettyDateString(result.datumPmZahajeniUpadku || result.datumPmUkonceniUpadku, 'short')
    if (!isStartDateAvailable) {
      // pokud neni k dispozici datum zalozeni tak zobrazuji datum ikončení
      tableTitles[1] = t('results.general.table.ended')
    }
    return {
      id: String(i),
      values: [formName(result), formattedDate, formState(result)]
    }
  })

  return { tableTitles, results }
}

export function getDetailRows(detail: DetailData, t: TFunction) {
  // const descrCondition =
  //   detail.current_price_date &&
  //   detail.current_price_value &&
  //   detail.datetime_zalozeni &&
  //   new Date(detail.current_price_date) > new Date(detail.datetime_zalozeni) &&
  //   Number(detail.current_price_value) > Number(detail.one_time_price)

  const descrCondition = detail.current_price_date && detail.current_price_value

  const descriptionText = descrCondition
    ? t('results.detail.nonmonetary.descriprion.title', {
        date: prettyDateString(detail.current_price_date),
        price: splitThousands(detail.current_price_value, ' ')
      })
    : ''

  const nonmonetaryDescription =
    detail.nonmonetary_description && detail.nonmonetary_description !== 'NULL' ? detail.nonmonetary_description : ''
  return {
    title: detail.ex_cislo,
    rows: [
      createItem(t('results.detail.execution.office.name'), detail.jmeno_kancelare),
      createItem(t('results.detail.ex.email'), detail.ex_email || ''),
      createItem(
        t('results.detail.date.creation'),
        prettyDateString(detail.datetime_zalozeni) || detail.datetime_zalozeni
      ),
      createItem(
        t('results.detail.price.regular'),
        detail.regular_price && Number(detail.regular_price) > 0
          ? t('payment.amount', {
              amount: splitThousands(detail.regular_price, ' ')
            })
          : ''
      ),
      createItem(
        t('results.detail.price.onetime'),
        detail.one_time_price
          ? t('payment.amount', {
              amount: splitThousands(detail.one_time_price, ' ')
            })
          : t('results.detail.price.notShown')
      ),
      createItem(t('results.detail.monetary.descriprion'), detail.monetary_description, true, descriptionText),
      createItem(t('results.detail.nonmonetary.descriprion'), nonmonetaryDescription, true)
    ]
  }
}

export function getEligibleRows(eligible: Eligible, t: TFunction, filter = true) {
  const { adresa, datum_narozeni, datum_upravy, datum_vlozeni, ico, jmeno, status, typ_osoby } = eligible
  return {
    name: jmeno,
    rows: [
      createItem(t('results.detail.birth'), prettyDateString(datum_narozeni) || datum_narozeni),
      createItem(t('results.detail.address'), adresa),
      createItem(t('results.detail.eligible.ico'), ico || ''),
      createItem(t('results.detail.person'), getLongPersonType(typ_osoby, t)),
      createItem(t('results.detail.eligible.status'), t(eligibleStatusMap[status])),
      createItem(t('results.detail.eligible.date.insert'), prettyDateString(datum_vlozeni) || datum_vlozeni),
      createItem(t('results.detail.eligible.date.update'), prettyDateString(datum_upravy) || datum_upravy)
    ].filter(v => (filter ? !!v : true))
  }
}

export function getEligibleRowsPdf(eligible: Eligible, t: TFunction, filter = true) {
  const { adresa, datum_narozeni, datum_vlozeni, ico, jmeno, status, typ_osoby } = eligible
  return {
    rows: [
      createItem(t('results.detail.name'), jmeno),
      createItem(t('results.detail.eligible.birth.pdf'), prettyDateString(datum_narozeni, 'numeric') || '-'),
      createItem(t('results.detail.address'), adresa),
      createItem(t('results.detail.eligible.ico'), ico || '-'),
      createItem(t('results.detail.eligible.person.pdf'), getShortPersonType(typ_osoby, t)),
      createItem(t('results.detail.eligible.status.pdf'), t(eligibleStatusMap[status])),
      createItem(t('results.detail.eligible.date.insert.pdf'), prettyDateString(datum_vlozeni, 'numeric') || '-')
    ].filter(v => (filter ? !!v : true))
  }
}

export function graceSummerExecutionEvaluation(execution: ResultRecord, insolvencies: IsirData[]) {
  const hasInsolvency = insolvencies.length > 0
  const hasEndedInsolvency = hasInsolvency
    ? insolvencies.some(insolvency => new Date(insolvency.datumPmUkonceniUpadku) < new Date())
    : false
  const exDate = new Date(execution.executionDate)
  const thresholdDate = new Date('2021-10-28')
  if (!exDate || !execution.personType) {
    return null
  }

  return {
    noInsolvency: !hasInsolvency || hasEndedInsolvency,
    correctPersonType: execution.personType === 'F',
    correctDate: exDate < thresholdDate
  }
}

export function isPositiveResult(activeChip: ResultChips, results?: GeneralResponseData) {
  switch (activeChip) {
    case ResultChips.DPH:
      return !!results?.dph.filter(dphItem => dphItem && dphItem.nespolehlivyPlatce === DphResult.POSITIVE).length
    case ResultChips.INSOLVENCY:
      return !!results?.isir?.length
    default:
      return !!results?.ceecr.length
  }
}

export function getExportName(activeChip: ResultChips, date: number | undefined, t: TFunction) {
  const prefix = isCed() ? t('ced.export.prefix') : t('results.export.prefix')
  return `${prefix}-${t('results.export.general')}-${exportTypeMap[activeChip]}-${getDateDigitsChain(
    date ? date * 1000 : undefined
  )}.pdf`
}

/* eslint-enable camelcase */
