import color from 'color'
import { isCed } from './app'
import { Theme } from '../components/providers/types'

const THEME_KEY_EX = 'EXECTIONS_THEME_NAME'
const THEME_KEY_CED = 'CED_THEME_NAME'
export const THEME_KEY = isCed() ? THEME_KEY_CED : THEME_KEY_EX

export function getInitialTheme() {
  const turnedTheme = localStorage.getItem(THEME_KEY) as Theme
  return turnedTheme && [Theme.DARK, Theme.LIGHT].includes(turnedTheme)
    ? turnedTheme
    : window.matchMedia('(prefers-color-scheme: dark)').matches
    ? Theme.DARK
    : Theme.LIGHT
}

export function removeAlpha(alphaColor: color, background: color) {
  const rgb = alphaColor.rgb().array().slice(0, 3)
  const alpha = alphaColor.alpha()
  const bg = background.rgb().array().slice(0, 3)

  return color(
    rgb.map((n, i) => alpha * n + (1 - alpha) * bg[i]),
    'rgb'
  )
}
