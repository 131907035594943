import color from 'color'
import { Theme } from '../components/providers/types'

export type ColorSet = { [key in ColorSets]: { [key in Theme]: color } }
export enum ColorSets {
  MAIN = 'MAIN',
  BLACK = 'BLACK',
  PURE_BLACK = 'PURE_BLACK',
  BLACK_SHADOW = 'BLACK_SHADOW',
  PURE_WHITE = 'PURE_WHITE',
  WHITE = 'WHITE',
  WHITE_80 = 'WHITE_80',
  WHITE_60 = 'WHITE_60',
  WHITE_SMOKE_20 = 'WHITE_SMOKE_20',
  WHITE_SMOKE = 'WHITE_SMOKE',
  GRAY_BROWN = 'GRAY_BROWN',
  GRAY_BROWN_LIGHT = 'GRAY_BROWN_LIGHT',
  GRAY_BROWN_DARK = 'GRAY_BROWN_DARK',
  GRAY_BROWN_20 = 'GRAY_BROWN_20',
  GRAY_BLUE_05 = 'GRAY_BLUE_05',
  GRAY_BLUE_12 = 'GRAY_BLUE_12',
  GRAY_BLUE_65 = 'GRAY_BLUE_65',
  PURE_GRAY_BLUE_65 = 'PURE_GRAY_BLUE_65',
  RED = 'RED',
  RED_DARK = 'RED_DARK',
  RED_DARK_70 = 'RED_DARK_70',
  RED_DARK_12 = 'RED_DARK_12',
  RED_DARKEN_1 = 'RED_DARKEN_1',
  RED_DARKEN_2 = 'RED_DARKEN_2',
  RED_DARKEN_3 = 'RED_DARKEN_3',
  GREEN = 'GREEN',
  FOREST_GREEN = 'FOREST_GREEN',
  GRAY_20 = 'GRAY_20',
  GRAY_40 = 'GRAY_40',
  GRAY_05 = 'GRAY_05',
  GRAY = 'GRAY',
  LIGHT_GRAY = 'LIGHT_GRAY',
  PURE_LIGHT_GRAY = 'PURE_LIGHT_GRAY',
  GRAY_DOMINANT = 'GRAY_DOMINANT',
  GRAY_DOMINANT_20_100 = 'GRAY_DOMINANT_20_100',
  BLACK_GRAY = 'BLACK_GRAY',
  BLUE_PURPLE = 'BLUE_PURPLE',
  BLUE_MAIN = 'BLUE_MAIN',
  BLUE_MAIN_SHADOW = 'BLUE_MAIN_SHADOW',
  BLUE_MAIN_12 = 'BLUE_MAIN_12',
  BLUE_MAIN_50 = 'BLUE_MAIN_50',
  BLUE_MAIN_80 = 'BLUE_MAIN_80',
  BLUE_LIGHT = 'BLUE_LIGHT',
  ORANGE = 'ORANGE',
  OPOSITE = 'OPOSITE',
  TRANSPARENT = 'TRANSPARENT',
  YELLOW = 'YELLOW'
}

export const AppColors: ColorSet = {
  [ColorSets.MAIN]: {
    [Theme.DARK]: color('#1F2123'),
    [Theme.LIGHT]: color('#f6f8fc')
  },
  [ColorSets.BLACK]: {
    [Theme.DARK]: color('#fff'),
    [Theme.LIGHT]: color('#001428')
  },
  [ColorSets.BLACK_GRAY]: {
    [Theme.DARK]: color('#403f3b'),
    [Theme.LIGHT]: color('#001428')
  },
  [ColorSets.PURE_BLACK]: {
    [Theme.DARK]: color('#787279'),
    [Theme.LIGHT]: color('#000')
  },
  [ColorSets.BLACK_SHADOW]: {
    [Theme.DARK]: color('#000').alpha(0.2),
    [Theme.LIGHT]: color('#000').alpha(0.12)
  },
  [ColorSets.WHITE]: {
    [Theme.DARK]: color('#403f3b'),
    [Theme.LIGHT]: color('#fff')
  },
  [ColorSets.PURE_WHITE]: {
    [Theme.DARK]: color('#fff'),
    [Theme.LIGHT]: color('#fff')
  },
  [ColorSets.WHITE_80]: {
    [Theme.DARK]: color('#403f3b').alpha(0.8),
    [Theme.LIGHT]: color('#fff').alpha(0.8)
  },
  [ColorSets.WHITE_60]: {
    [Theme.DARK]: color('#403f3b').alpha(0.6),
    [Theme.LIGHT]: color('#fff').alpha(0.6)
  },
  [ColorSets.WHITE_SMOKE_20]: {
    [Theme.DARK]: color('#1F2123').alpha(0.2),
    [Theme.LIGHT]: color('#F5F5F5').alpha(0.2)
  },
  [ColorSets.WHITE_SMOKE]: {
    [Theme.DARK]: color('#1F2123'),
    [Theme.LIGHT]: color('#F5F5F5')
  },
  [ColorSets.GRAY_BROWN]: {
    [Theme.DARK]: color('#faebd7'),
    [Theme.LIGHT]: color('#faebd7')
  },
  [ColorSets.GRAY_BROWN_LIGHT]: {
    [Theme.DARK]: color('#faebd7').alpha(0.5),
    [Theme.LIGHT]: color('#faebd7').alpha(0.5)
  },
  [ColorSets.GRAY_DOMINANT]: {
    [Theme.DARK]: color('#d3d3d3'),
    [Theme.LIGHT]: color('#555')
  },
  [ColorSets.GRAY_DOMINANT_20_100]: {
    [Theme.DARK]: color('#d3d3d3'),
    [Theme.LIGHT]: color('#555').alpha(0.2)
  },
  [ColorSets.GRAY_BROWN_DARK]: {
    [Theme.DARK]: color('#555'),
    [Theme.LIGHT]: color('#b19c80')
  },
  [ColorSets.GRAY_BROWN_20]: {
    [Theme.DARK]: color('#555').alpha(0.5),
    [Theme.LIGHT]: color('#faebd7').alpha(0.05)
  },
  [ColorSets.GRAY_BLUE_05]: {
    [Theme.DARK]: color('#000').alpha(0.2),
    [Theme.LIGHT]: color('#001428').alpha(0.05)
  },
  [ColorSets.GRAY_BLUE_12]: {
    [Theme.DARK]: color('#555').alpha(0.8),
    [Theme.LIGHT]: color('#001428').alpha(0.12)
  },
  [ColorSets.GRAY_BLUE_65]: {
    [Theme.DARK]: color('#B2BCBD'),
    [Theme.LIGHT]: color('#001428').alpha(0.65)
  },
  [ColorSets.PURE_GRAY_BLUE_65]: {
    [Theme.DARK]: color('#001428').alpha(0.65),
    [Theme.LIGHT]: color('#001428').alpha(0.65)
  },
  [ColorSets.RED]: {
    [Theme.DARK]: color('#ff0000'),
    [Theme.LIGHT]: color('#ff0000')
  },
  [ColorSets.RED_DARK]: {
    [Theme.DARK]: color('#dc143c'),
    [Theme.LIGHT]: color('#dc143c')
  },
  [ColorSets.RED_DARK_70]: {
    [Theme.DARK]: color('#dc143c').alpha(0.7),
    [Theme.LIGHT]: color('#dc143c').alpha(0.7)
  },
  [ColorSets.RED_DARK_12]: {
    [Theme.DARK]: color('#dc143c').alpha(0.12),
    [Theme.LIGHT]: color('#dc143c').alpha(0.12)
  },
  [ColorSets.RED_DARKEN_1]: {
    [Theme.DARK]: color('#ef5350'),
    [Theme.LIGHT]: color('#ef5350')
  },
  [ColorSets.RED_DARKEN_2]: {
    [Theme.DARK]: color('#e53935'),
    [Theme.LIGHT]: color('#e53935')
  },
  [ColorSets.RED_DARKEN_3]: {
    [Theme.DARK]: color('#b71c1c'),
    [Theme.LIGHT]: color('#b71c1c')
  },
  [ColorSets.GREEN]: {
    [Theme.DARK]: color('#61c9a7'),
    [Theme.LIGHT]: color('#61c9a7')
  },
  [ColorSets.FOREST_GREEN]: {
    [Theme.DARK]: color('#006400'),
    [Theme.LIGHT]: color('#4da316')
  },
  [ColorSets.LIGHT_GRAY]: {
    [Theme.DARK]: color('#d3d3d3'),
    [Theme.LIGHT]: color('#d3d3d3').alpha(0.2)
  },
  [ColorSets.PURE_LIGHT_GRAY]: {
    [Theme.DARK]: color('#d3d3d3').alpha(0.2),
    [Theme.LIGHT]: color('#d3d3d3').alpha(0.2)
  },
  [ColorSets.GRAY_20]: {
    [Theme.DARK]: color('#555'),
    [Theme.LIGHT]: color('#787276').alpha(0.2)
  },
  [ColorSets.GRAY_40]: {
    [Theme.DARK]: color('#000').alpha(0.4),
    [Theme.LIGHT]: color('#787276').alpha(0.4)
  },
  [ColorSets.GRAY]: {
    [Theme.DARK]: color('#33322F'),
    [Theme.LIGHT]: color('#787276')
  },
  [ColorSets.GRAY_05]: {
    [Theme.DARK]: color('#33322F').alpha(0.5),
    [Theme.LIGHT]: color('#787276').alpha(0.05)
  },
  [ColorSets.BLUE_PURPLE]: {
    [Theme.DARK]: color('#222168'),
    [Theme.LIGHT]: color('#222168')
  },
  [ColorSets.BLUE_MAIN]: {
    [Theme.DARK]: color('#1E88E5'),
    [Theme.LIGHT]: color('#1E88E5')
  },
  [ColorSets.BLUE_MAIN_SHADOW]: {
    [Theme.DARK]: color('#000').alpha(0.5),
    [Theme.LIGHT]: color('#1E88E5').alpha(0.12)
  },
  [ColorSets.BLUE_MAIN_12]: {
    [Theme.DARK]: color('#1E88E5').alpha(0.12),
    [Theme.LIGHT]: color('#1E88E5').alpha(0.12)
  },
  [ColorSets.BLUE_MAIN_50]: {
    [Theme.DARK]: color('#1E88E5').alpha(0.5),
    [Theme.LIGHT]: color('#1E88E5').alpha(0.5)
  },
  [ColorSets.BLUE_MAIN_80]: {
    [Theme.DARK]: color('#1E88E5').alpha(0.8),
    [Theme.LIGHT]: color('#1E88E5').alpha(0.8)
  },
  [ColorSets.BLUE_LIGHT]: {
    [Theme.DARK]: color('#1E88E5'),
    [Theme.LIGHT]: color('#00bfff')
  },
  [ColorSets.ORANGE]: {
    [Theme.DARK]: color('#F9a602'),
    [Theme.LIGHT]: color('#F9a602')
  },
  [ColorSets.OPOSITE]: {
    [Theme.DARK]: color('#343434'),
    [Theme.LIGHT]: color('#fff')
  },
  [ColorSets.TRANSPARENT]: {
    [Theme.DARK]: color('#fff').alpha(0),
    [Theme.LIGHT]: color('#fff').alpha(0)
  },
  [ColorSets.YELLOW]: {
    [Theme.DARK]: color('#fbc224'),
    [Theme.LIGHT]: color('#fbc224')
  }
}
