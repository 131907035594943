import { Xhr } from '../types'

export type IsirModule = ReturnType<typeof createModule>

export function createModule(xhr: Xhr) {
  return {
    getDetail: (url: string) => {
      return xhr.get<{ html: string }>(url)
    }
  }
}
