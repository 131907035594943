import { toast } from 'react-toastify'
import { register } from 'register-service-worker'
import { isStandAlone } from './devices'
import { Toast as toastComponentFunction } from '../components/UI/Toast'

export function applyRegistration(reg: ServiceWorkerRegistration) {
  const registrationWaiting = reg.waiting
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
    registrationWaiting.addEventListener('statechange', e => {
      // @ts-ignore
      if (e && e.target && e.target.state === 'activated') {
        window.location.reload()
      }
    })
  }
}

export function registerServiceWorker() {
  register('./service-worker.js', {
    registrationOptions: { scope: './' },
    ready() {
      console.log('Service worker is active.')
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(reg) {
      if (isStandAlone()) {
        toast.info(() => toastComponentFunction({ serviceWorkeregistration: reg }), {
          toastId: 'updateServiceWorkerToast'
        })
      } else {
        applyRegistration(reg)
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
