import { stringify } from 'query-string'
import { FormMessage } from '../../components/forms/types'
import { PaymentData } from '../../components/payment/types'
import { DetailDataResponse, GeneralDataResponse, Statistics } from '../../components/results/types'
import { Xhr } from '../types'

export type ExecutionsModule = ReturnType<typeof createModule>

export function createModule(xhr: Xhr) {
  return {
    getPayment: (data: PaymentData) => {
      return xhr.post<{ gwUrl: string }>('/payment/create', data)
    },

    getData: (paymentId: string | number) => {
      return xhr.get<GeneralDataResponse | DetailDataResponse>(`/data/${paymentId}`)
    },

    sendFormMessage: (params: FormMessage) => {
      const { isInquiry, ...data } = params
      return xhr.post(`/messages/form/question?${stringify({ isInquiry })}`, data)
    },

    getStatistics: (psw: string) => {
      return xhr.get<Statistics>(`/statistics/${psw}`)
    }
  }
}
