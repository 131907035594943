import i18next from 'i18next'
import ICU from 'i18next-icu'

export enum SupportedLanguages {
  CZ = 'cs_CZ'
}

type Bundle = {
  [key: string]: string
}

export function loadTranslations(lang: SupportedLanguages) {
  /* eslint @typescript-eslint/no-var-requires: "off" */
  const bundle = require(`../translations/${lang}/bundle.json`)
  return bundle
}

export function initLocalization(language: string, translations: Bundle) {
  i18next.use(ICU).init({
    initImmediate: false,
    lng: language,
    parseMissingKeyHandler: key => `????? ${key} ?????`,
    interpolation: {
      prefix: '{',
      suffix: '}'
    },
    nsSeparator: false,
    keySeparator: false,
    resources: {
      [language]: {
        translation: translations
      }
    }
  })
  return i18next
}
