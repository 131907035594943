import React, { ReactNode } from 'react'
import { Styled } from './styled/TextButton.styled'
import { ColorSets } from '../../colors/colors'

type Props = {
  text: ReactNode
  onClick?: () => void
  withShadow?: boolean
  className?: string
  defaultSpacing?: boolean
  bgColorSet?: ColorSets
}
export function TextButton({ text, onClick, withShadow, className, bgColorSet, defaultSpacing = true }: Props) {
  return (
    <Styled.Button
      showShaddow={!!withShadow}
      onClick={onClick}
      className={className}
      defaultSpacing={defaultSpacing}
      $bgColorSet={bgColorSet}
    >
      <span>{text}</span>
    </Styled.Button>
  )
}
