import React, { createContext, ReactNode, useEffect, useState } from 'react'

import { ThemeProvider as Provider } from 'styled-components'
import { Theme } from './types'
import { THEME_KEY } from '../../utils/themeUtils'

type Props = {
  themeName: Theme
  children: ReactNode
}

type ThemeContextValue = {
  theme: Theme
  setTheme: (theme: Theme) => void
}

export const ThemeContext = createContext<ThemeContextValue | null>(null)

export function ThemeProvider(props: Props) {
  const [theme, setTheme] = useState(props.themeName)

  useEffect(() => {
    localStorage.setItem(THEME_KEY, theme)
  }, [theme])

  useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', e => setTheme(e.matches ? Theme.DARK : Theme.LIGHT))

    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {})
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Provider theme={{ name: theme }}>{props.children}</Provider>
    </ThemeContext.Provider>
  )
}
