import React, { useContext } from 'react'
import { ApiClient } from '../api/types'

export const ApiClientContext = React.createContext<ApiClient | null>(null)

export function useApiClient() {
  const apiClient = useContext(ApiClientContext)
  if (!apiClient) {
    throw new Error(
      'API Client is missing in context, you probably forgot to wrap your component with ApiClientContext.Provider'
    )
  }
  return apiClient
}
