import { DefaultTheme } from 'styled-components'
import { AppColors, ColorSets } from '../colors/colors'
import { removeAlpha } from '../utils/themeUtils'
// import { Theme } from '../components/providers/types'

// Prozatim vzdycky light mode nez bude odladen darkmode
// export const colorSet =
//   <P extends { theme: DefaultTheme }>(set: ColorSets) =>
//   (props: P): string => {
//     const theme = props.theme.name === Theme.DARK ? Theme.DARK : Theme.DARK
//     return AppColors[set][theme].toString() // props.theme.name
//   }

export const colorSet =
  <P extends { theme: DefaultTheme }>(set: ColorSets) =>
  (props: P): string =>
    AppColors[set][props.theme.name].toString()

export const colorSetNoAlpha =
  <P extends { theme: DefaultTheme }>(colorSet: ColorSets, background: ColorSets) =>
  (props: P): string => {
    const { name: themeName } = props.theme
    const fg = AppColors[colorSet][themeName]
    const bg = AppColors[background][themeName]

    return removeAlpha(fg, bg).toString()
  }
