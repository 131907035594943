import { createItem } from './utils'
import { ColorSets } from '../../colors/colors'
import { ItemType } from '../payment/types'
import { QueryData } from '../searchSections/types'

export type ResultRecord = {
  name: string
  birthDate: string
  address: string
  executionDate: string
  executionId: string
  executorName?: string
  executorEmail?: string
  causeId: string
  customId: string
  personType: PersonType
}

export type PersonType = 'F' | 'P'
export enum EligibleStatus {
  CURRENT = 'current',
  FORMER = 'former',
  PRECEDENT = 'precedent '
}

export type Subject = {
  ['subject_id']: number
  ['typ_osoby']: PersonType
  jmeno: string
  ['datum_narozeni']: string
  ic: string | null
  adresa: string
  ['auto_close_at']: string | null // kdy bude proveden výmaz subjektu z CEE
}
export type Document = {
  ['document_id']: number
  subjects: number[]
  ['document_type_id']: number
  ['document_type_description']: string
  ['id_soudu']: number
  ['jmeno_soudu']: string
  ['spisova_znacka_soudu']: string
  ['document_vydano']: string
  ['document_vlozeno']: string
  ['document_modified']: string | null
  text: string | null
}

export type Eligible = {
  ['eligible_id']: string
  ['typ_osoby']: PersonType
  ['jmeno']: string
  ['datum_narozeni']: string
  ['ico']: string | null
  ['adresa']: string
  ['adm']: number
  ['datum_upravy']: string
  ['datum_vlozeni']: string
  ['status']: EligibleStatus
}

/* eslint-disable camelcase */
export type Enforcement = {
  enforcement_id: string
  enforcement_type_description: string
  enforcement_type_id: number
  inserted_at: string | null
  issued_at: string
  issuer: string
  jmeno_soudu: string
  modified_at: string | null
  reference_number: string
  spisova_znacka_soudu: string
}
/* eslint-enable camelcase */

export type GeneralData = {
  subjects: Subject[]
  ['subject_count']: string
  ['ex_cislo']: string
  ['ex_name']?: string // jmeno exekutora
  ['ex_email']?: string // email exekutora
  ['cause_id']: string
  ['datetime_zalozeni']: string // kdy byla kauza vlozena do systemu
  ['datetime_zapisu']: string // deprecated
  ['posledni_uprava']: string
  ['date_vydano']: string // datum vydani prvniho usneseni
  ['datum_soudniho_narizeni']: string // datum vydani prvniho usneseni, stejne jako date_vydano
}

export type DetailData = {
  ['cause_id']: string
  ['current_price_value']: string
  ['current_price_date']: string
  ['datetime_zalozeni']: string
  ['datetime_vlozeni']: string | null
  ['datetime_zapisu']: string | null
  ['id_exekutora']: number
  ['id_kancelare']: number
  ['jmeno_kancelare']: string
  ['ex_cislo']: string
  ['ex_name']?: string
  ['ex_email']?: string
  ['one_time_price']: string | null
  ['regular_price']: string | null
  ['monetary_description']: string
  ['nonmonetary_description']: string | null
  subjects: Subject[]
  documents: Document[]
  eligibles: Eligible[] | null
  enforcements: Enforcement[] | null
}

export type IsirData = {
  ic: string
  cisloSenatu: number
  druhVec: string
  rocnik: number
  bcVec: number
  nazevOrganizace: string
  rc: string
  datumNarozeni: string
  titulPred: string
  titulZa: string
  jmeno: string
  nazevOsoby: string
  druhAdresy: string
  mesto: string
  ulice: string
  cisloPopisne: string
  okres: string
  zeme: string
  psc: string
  druhStavKonkursu: string
  casSynchronizace: string
  pocetVysledku: number
  relevanceVysledku: number
  urlDetailRizeni: string
  dalsiDluzniukVRizeni: string
  datumPmZahajeniUpadku: string
  datumPmUkonceniUpadku: string
}

export enum DphResult {
  POSITIVE = 'ANO',
  NEGATIVE = 'NE',
  NOT_FOUND = 'NENALEZED'
}

export type DphData = {
  cisloFu: string
  datumZverejneniNespolehlivosti?: string // "2020-07-05"
  dic: string
  nespolehlivyPlatce: DphResult
}

export enum DataSource {
  CEECR = 'ceecr',
  ISIR = 'isir',
  DPH = 'dph'
}

export type GeneralResponseData = {
  ceecr: GeneralData[]
  isir: IsirData[] | null
  dph: (DphData | null)[]
}

export type DetailResponseData = {
  ceecr: DetailData[]
}

export type TransformedDocument = Omit<Document, 'subjects'> & { subjects: Subject[] }

type ResponseCommon = { dataType: ItemType; created: number; newData: boolean; email: string }
export type GeneralDataResponse = ResponseCommon & { data: GeneralResponseData; params: QueryData }
export type DetailDataResponse = ResponseCommon & { data: DetailResponseData }

export type ErrorResponse = { title: string; message: string }

export enum ArrowDirection {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT'
}

export type CardRow = {
  name: string
  value: string
}

export const trials = [
  { name: 'Městský soud v Praze', short: 'MSPH' },
  { name: 'Krajský soud v Praze', short: 'KSPH' },
  { name: 'Krajský soud v Českých Budějovicích', short: 'KSCB' },
  { name: 'Krajský soud v Českých Budějovicích – pobočka v Táboře', short: 'KSTB' },
  { name: 'Krajský soud v Plzni', short: 'KSPL' },
  { name: 'Krajský soud v Plzni – pobočka v Karlových Varech', short: 'KSKV' },
  { name: 'Krajský soud v Ústí nad Labem', short: 'KSUL' },
  { name: 'Krajský soud v Ústí nad Labem – pobočka v Liberci', short: 'KSLB' },
  { name: 'Krajský soud v Hradci Králové', short: 'KSHK' },
  { name: 'Krajský soud v Hradci Králové – pobočka v Pardubicích', short: 'KSPA' },
  { name: 'Krajský soud v Brně', short: 'KSBR' },
  { name: 'Krajský soud v Brně – pobočka v Jihlavě', short: 'KSJI' },
  { name: 'Krajský soud v Brně – pobočka ve Zlíně', short: 'KSZL' },
  { name: 'Krajský soud v Ostravě', short: 'KSOS' },
  { name: 'Krajský soud v Ostravě – pobočka v Olomouci', short: 'KSOL' },
  { name: 'Vrchní soud v Praze', short: 'VSPH' },
  { name: 'Vrchní soud v Olomouci', short: 'VSOL' },
  { name: 'Nejvyšší soud', short: 'NSCR' }
].map(s => ({ ...s, name: s.name.replace(/[\s|–|-]/g, '') }))

export enum ResultChips {
  EXECUTION = 'EXECUTION',
  INSOLVENCY = 'INSOLVENCY',
  DPH = 'DPH'
}

export type TableRow = {
  id: string
  values: string[]
}

export type Statistics = {
  data: {
    date: number
    requests: number
    requestsPrice: number
    orders: number
    brutto: number
  }[]
}

export type SubjectRowValue = {
  text: string
  tooltip?: string
  colorSet?: ColorSets
}

export type CardData = {
  id: number | string
  rows: ReturnType<typeof createItem>[]
  subjects?: Subject[]
}
