import { createModule as createExecutionsModule } from './modules/executions'
import { createModule as createIsirModule } from './modules/isir'

import { ApiClient } from './types'
import { createXhr } from './xhr'
import { isProductionAPI } from '../components/results/utils'

export function createApiClient(baseUrl = ''): ApiClient {
  const defaultUrl = isProductionAPI()
    ? 'https://backend.vyhledejexekuce.cz'
    : 'https://q2g2fm46sg.execute-api.eu-west-1.amazonaws.com/Prod'

  const xhr = createXhr(baseUrl || defaultUrl)

  const executions = createExecutionsModule(xhr)
  const isir = createIsirModule(xhr)

  return {
    xhr,
    baseUrl,
    executions,
    isir
  }
}
