import { stringify } from 'query-string'
import { Xhr } from './types'

function sanitizeResponse(response: Response) {
  if (response.status >= 200 && response.status < 400) {
    if (response.headers.get('content-type')?.includes('application/json')) {
      return response.json()
    }
    return response.text()
  }

  return response.json().then(error => {
    error.response = response
    throw error
  })
}

function createBody<T>(data: T, wwwFormUrlEncoded: boolean) {
  if (wwwFormUrlEncoded) {
    // @ts-ignore ocekava flat strukturu, bude potreba trochu prekopat API clienta pro tech par requestu...
    return stringify(data)
  }
  return data instanceof FormData ? data : JSON.stringify(data)
}

export function createXhr(baseUrl: string): Xhr {
  const makeRequest = <TReponse, TBody extends {}>(
    method: RequestInit['method'],
    url: string,
    data?: TBody,
    wwwFormUrlEncoded = false
  ): Promise<TReponse> => {
    const headers = new Headers()
    headers.set('Accept', 'application/json')

    if (data && !(data instanceof FormData)) {
      headers.set('Content-Type', 'application/json')
    }

    if (wwwFormUrlEncoded) {
      headers.set('Content-Type', 'application/x-www-form-urlencoded')
    }

    const body = createBody(data, wwwFormUrlEncoded)

    return fetch(`${baseUrl}${url}`, {
      method,
      body,
      headers
    }).then(sanitizeResponse)
  }

  return {
    get: (url: string) => {
      return makeRequest('GET', url)
    },
    post: <TReponse, TBody extends {}>(url: string, body?: TBody, wwwFormUrlEncoded?: boolean) => {
      return makeRequest<TReponse, TBody>('POST', url, body, wwwFormUrlEncoded)
    },
    put: <TReponse, TBody extends {}>(url: string, body?: TBody, wwwFormUrlEncoded?: boolean) => {
      return makeRequest<TReponse, TBody>('PUT', url, body, wwwFormUrlEncoded)
    },
    delete: <TBody extends {}>(url: string, body?: TBody) => {
      return makeRequest('DELETE', url, body)
    }
  }
}
