import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { I18nextProvider } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
// import App from './App'
import { createApiClient } from './api/createApiClient'
import { ThemeProvider } from './components/providers/ThemeProvider'
import { ApiClientContext } from './hooks/useApiClient'
import reportWebVitals from './reportWebVitals'
import { isStandAlone } from './utils/devices'
import { initLocalization, loadTranslations, SupportedLanguages } from './utils/i18t'
import { registerServiceWorker } from './utils/serviceWorker'
import { getInitialTheme } from './utils/themeUtils'

const App = lazy(() => import('./App'))

// ReactGA.initialize('G-0D62KGBWJY')
// ReactGA.initialize('UA-207400343-1')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false,
      retry: false,
      onError: err => {
        console.log('React query error: ', err)
      }
    },
    mutations: {
      onError: err => {
        console.log('React query error - mutation: ', err)
      }
    }
  }
})

const translations = initLocalization('cs', loadTranslations(SupportedLanguages.CZ))

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={translations}>
        <ApiClientContext.Provider value={createApiClient()}>
          <BrowserRouter>
            <ThemeProvider themeName={getInitialTheme()}>
              <Suspense fallback={null}>
                <App />
              </Suspense>
            </ThemeProvider>
          </BrowserRouter>
        </ApiClientContext.Provider>
      </I18nextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
if (isStandAlone()) {
  registerServiceWorker()
}
