import styled from 'styled-components'
import { Styled as TextButtonStyled } from './TextButton.styled'
import { ColorSets } from '../../../colors/colors'
import { colorSet } from '../../../mixin/colorSet'
import { TextButton } from '../TextButton'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Text = styled.div`
  font-size: 16px;
`

const ConfirmButton = styled(TextButton)`
  ${TextButtonStyled} {
    margin: 10px 0px 0 0;
    min-width: 0;
    padding: 5px;
    background-color: ${colorSet(ColorSets.ORANGE)};
    color: ${colorSet(ColorSets.BLACK)};
    width: 100%;
    max-width: 250px;
  }
`

export const Styled = {
  Container,
  Text,
  ConfirmButton
}
