import { TFunction } from 'i18next'
import {
  CardData,
  DetailData,
  Eligible,
  EligibleStatus,
  GeneralData,
  IsirData,
  ResultChips,
  ResultRecord
} from './types'
import { getDocumentRows, getEligibleRowsPdf, getEnforcementRows, getTrialShort } from './utils'
import { Item } from '../payment/types'

export const eligibleStatusMap: { [key in EligibleStatus]: string } = {
  [EligibleStatus.CURRENT]: 'results.detail.eligible.status.current',
  [EligibleStatus.FORMER]: 'results.detail.eligible.status.former',
  [EligibleStatus.PRECEDENT]: 'results.detail.eligible.status.precedent'
}

export function transformCeecrData(data?: GeneralData[]) {
  const results =
    data?.flatMap<ResultRecord>((result, r) => {
      const subjects = Array.isArray(result.subjects)
        ? result.subjects.map<ResultRecord>((subject, i) => ({
            name: subject?.jmeno,
            address: subject?.adresa,
            birthDate: subject?.datum_narozeni,
            executionDate: result.date_vydano || result.datum_soudniho_narizeni || result.datetime_zalozeni,
            executionId: result.ex_cislo,
            causeId: result.cause_id,
            customId: `${result.cause_id}_subject_${i}_result_${r}`,
            personType: subject.typ_osoby,
            executorEmail: result.ex_email,
            executorName: result.ex_name
          }))
        : []

      return subjects
    }) || []

  return results
}

export function transformDocuments(data: DetailData[] | undefined, t: TFunction) {
  return data?.map<CardData[]>(detail => {
    return detail.documents
      ? detail.documents.map(document => {
          const subjects =
            document.subjects && Array.isArray(document.subjects)
              ? document.subjects.flatMap(subjectId => {
                  return detail.subjects.find(sub => sub.subject_id === subjectId) || []
                })
              : detail.subjects || []
          return {
            rows: getDocumentRows(document, t),
            subjects,
            id: document.document_id
          }
        })
      : []
  })
}

export function transformEnforcements(data: DetailData[] | undefined, t: TFunction) {
  return data?.map<CardData[]>(detail => {
    return detail.enforcements
      ? detail.enforcements.map(enforcement => {
          return {
            subjects: [],
            id: enforcement.enforcement_id,
            rows: getEnforcementRows(enforcement, t)
          }
        })
      : []
  })
}

export function getSelectedExecutions(items: Item[], data?: ResultRecord[]): { [key in string]: boolean } {
  return (
    data?.reduce(
      (obj, item) => ({
        ...obj,
        [item.customId]: items && items.length ? items.map(i => i.customId).includes(item.customId) || false : true
      }),
      {}
    ) || {}
  )
}

export function formName(data: IsirData) {
  return `${data.jmeno || ''} ${data.nazevOsoby || ''}`
}

export function formMark(data: IsirData) {
  return `${getTrialShort(data.nazevOrganizace)} ${data.cisloSenatu} ${data.druhVec} ${data.bcVec}/${data.rocnik}`
}

export function formAddress(data: IsirData) {
  const psc = data.psc ? `, PSČ ${data.psc}` : ''
  const village = data.okres ? `, okres ${data.okres}` : ''
  return `${data.mesto}, ${data.ulice} ${data.cisloPopisne}${psc}${village}`
}

export function formState(data: IsirData) {
  const lowerCase = data.druhStavKonkursu.toLowerCase()
  const state = lowerCase[0].toUpperCase() + lowerCase.substr(1)
  return state
}

export function transformEligiblesForPdf(eligibles: Eligible[], t: TFunction) {
  const transformedEligibles = eligibles.map(eligible => getEligibleRowsPdf(eligible, t, false))
  if (eligibles.length === 0) {
    return {}
  }
  const columns = transformedEligibles[0].rows
    .map(row => row.name)
    .reduce<{ [key: string]: string[] }>((colObject, name) => {
      const newObj = { ...colObject }
      newObj[name] = []
      return newObj
    }, {})

  transformedEligibles.forEach(eligible => {
    eligible.rows.forEach(row => {
      columns[row.name].push(row.value)
    })
  })

  return columns
}

export const exportTypeMap = {
  [ResultChips.DPH]: 'DPH',
  [ResultChips.EXECUTION]: 'CEE',
  [ResultChips.INSOLVENCY]: 'ISIR'
}
