import { isCed } from '../utils/app'

export const COMPANY = {
  NAME: 'Datlok s.r.o.',
  ICO: '14304511',
  DIC: 'CZ14304511',
  ADDRESS: 'Korunní 2569/108, Vinohrady, 101 00 Praha 10',
  PHONE: '+420 730 606 981',
  EMAIL_INFO: isCed() ? 'info@cedcr.cz' : 'info@vyhledejexekuce.cz',
  EMAIL_OWNER: isCed() ? 'info@cedcr.cz' : 'info@vyhledejexekuce.cz',
  DESCRIPTION: 'zapsané v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 362867', // zapsaný v živnostenském rejstříku vedeném Magistrátem města Kladna
  DPH: 'je plátce DPH',
  WEB: isCed() ? 'www.cedcr.cz' : 'www.vyhledejexekuce.cz'
}

export const BASIC_REPORT_PRICE = 199
export const DETAIL_REPORT_PRICE = 99
